.fire-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,69,0,1) 0%, rgba(255,0,0,1) 50%, rgba(139,0,0,1) 100%);
  color: white;
  font-size: 3rem;
  text-align: center;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  animation: crazy-flames 1s infinite;
    /* Centering the content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.fire-overlay.active {
  opacity: 1;
}

@keyframes crazy-flames {
  0% {
    transform: rotate(0deg) scale(1);
    filter: blur(1px);
  }
  25% {
    transform: rotate(3deg) scale(1.1);
    filter: blur(1px);
  }
  50% {
    transform: rotate(-3deg) scale(1.2);
    filter: blur(1px);
  }
  75% {
    transform: rotate(3deg) scale(1.3);
    filter: blur(1px);
  }
  100% {
    transform: rotate(-3deg) scale(1.4);
    filter: blur(1px);
  }
}

@keyframes text-flicker {
  0% {
    opacity: 1;
    text-shadow: 0 0 5px white, 0 0 10px red, 0 0 20px yellow;
  }
  50% {
    opacity: 0.5;
    text-shadow: none;
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 5px white, 0 0 10px red, 0 0 20px yellow;
  }
}

.fire-overlay.active span {
  animation: text-flicker 0.1s infinite;
  color: yellow;
}
.btn-group-toggle .btn label {
  margin-left: 10px; /* Add some space between radio button and label */
}

.btn-group-toggle .btn {
  padding: 10px 20px; /* Increase padding inside the buttons */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-group-toggle .btn input[type="radio"] {
  margin-right: 10px; /* Create space between radio button and text */
}

.btn-group-toggle .btn.active {
  background-color: #007bff; /* Use Bootstrap's primary color */
  border-color: #007bff;
  color: white;
}

body {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23d4cfdb' fill-opacity='0.1'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
  background-size: auto;
  min-height: 100vh; /* Ensure the background covers the entire screen */
  margin: 0;
  padding: 0;
}
.calculate-button {
  background-color: black;
}
#unitSwitch:hover {
  cursor: url('../public/pizza-slice.png'), auto; /* Custom pizza slice cursor */
}
.switch-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch-label {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.switch-label.active {
  color: black;
}

.switch-label:not(.active) {
  color: #c0c0c0;
}

.switch-input {
  appearance: none;
  -webkit-appearance: none;
  width: 50px;
  height: 25px;
  background-color: #c0c0c0;
  border-radius: 15px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch-input:checked {
  background-color: black;
}

.switch-input:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch-input:checked:before {
  transform: translateX(25px);
}

@media (max-width: 768px) {
  .result-display {
    margin-bottom: 40px; /* Adjust the value as needed */
  }
}
/* Instructions Styling */
.step-by-step {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-by-step h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.step-by-step p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

.step-by-step .step-number {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  padding: 5px 10px;
  margin-right: 10px;
  font-weight: bold;
}

.step-by-step .step-content {
  display: flex;
  align-items: center;
}

hr {
  border-top: 2px solid #ddd;
}

.text-center h3 {
  font-weight: 700;
  color: #212529;
}