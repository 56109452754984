.result-display {
    background-color: #f8f9fa; /* Light gray background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for polished look */
    padding: 1.5rem; /* Adjusted padding for container */
  }
  
  .result-title {
    font-size: 1.5rem;
    font-weight: 700; /* Clear visual hierarchy */
    color: #333;
    margin-bottom: 1rem;
  }
  
  .result-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Structured format */
    padding: 0.75rem 0; /* Added spacing */
    border-bottom: 1px solid #e0e0e0; /* Divider lines */
  }
  
  .result-item:last-child {
    border-bottom: none; /* Remove divider for last item */
  }
  
  .result-icon {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem; /* Spacing between icon and label */
  }
  
  .result-label {
    flex-grow: 1;
    font-size: 1rem;
    font-weight: 500; /* Font weight for readability */
    color: #555;
  }
  
  .result-value {
    font-size: 1rem;
    font-weight: 500; /* Font weight for readability */
    color: #555;
  }